const data = [
  {
    id: "razorpay",
    href: "https://razorpay.com/",
    scale: 1.05,
    logo: {
      src: require("../images/sponsors/razorpay.png"),
      height: 268,
      width: 1264,
    },
  },
  {
    id: "postman",
    href: "https://www.postman.com",
    scale: 1,
    logo: {
      src: require("../images/sponsors/postman.png"),
      height: 119,
      width: 385,
    },
  },
  {
    id: "mercari",
    href: "https://careers.mercari.com/hello-india/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/mercari.png"),
      height: 79,
      width: 342,
    },
  },
  {
    id: "cars24",
    href: "https://www.cars24.com",
    scale: 1,
    logo: {
      src: require("../images/sponsors/cars24.png"),
      height: 127,
      width: 245,
    },
  },
  {
    id: "porter",
    href: "https://porter.in/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/porter.png"),
      height: 112,
      width: 480,
    },
  },
  {
    id: "turing",
    href: "https://www.turing.com/",
    scale: 0.6,
    logo: {
      src: require("../images/sponsors/turing.png"),
      height: 75,
      width: 226,
    },
  },
  {
    id: "meesho",
    href: "https://meesho.com/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/meesho.png"),
      height: 138,
      width: 526,
    },
  },
  {
    id: "storyblok",
    href: "https://www.storyblok.com/?utm_source=reactdaybangalore&utm_medium=display&utm_campaign=TOF_EVT_RDB_TRA&utm_content=react_day_bangalore",
    scale: 1,
    logo: {
      src: require("../images/sponsors/storyblok.png"),
      height: 106,
      width: 502,
    },
  },
  // {
  //   id: "archimydes",
  //   href: "https://archimydes.dev/",
  //   scale: 1.15,
  //   logo: {
  //     src: require("../images/sponsors/archimydes.png"),
  //     height: 209,
  //     width: 1315,
  //   },
  // },
  {
    id: "geekyants",
    href: "https://geekyants.com/",
    logo: {
      src: require("../images/sponsors/geekyants.png"),
      height: 86,
      width: 422,
    },
  },
  {
    id: "groww",
    href: "https://groww.in/",
    logo: {
      src: require("../images/sponsors/groww.png"),
      height: 158,
      width: 557,
    },
  },
  {
    id: "smallcase",
    href: "https://www.smallcase.com/",
    logo: {
      src: require("../images/sponsors/smallcase.png"),
      height: 98,
      width: 386,
    },
  },
  {
    id: "assembly",
    href: "https://www.joinassembly.com/",
    logo: {
      src: require("../images/sponsors/assembly.png"),
      height: 80,
      width: 422,
    },
  },
  {
    id: "harness",
    href: "https://harness.io/",
    logo: {
      src: require("../images/sponsors/harness.png"),
      height: 98,
      width: 446,
    },
  },
  // {
  //   id: "hasura",
  //   href: "https://hasura.io/",
  //   scale: 0.9,
  //   logo: {
  //     src: require("../images/sponsors/hasura.png"),
  //     height: 78,
  //     width: 264,
  //   },
  // },
  {
    id: "swiggy",
    href: "https://careers.swiggy.com/#/careers?src=careers&search=title:react",
    scale: 0.6,
    logo: {
      src: require("../images/sponsors/swiggy.png"),
      height: 100,
      width: 345,
    },
  },
  {
    id: "xactly",
    href: "https://www.xactlycorp.com/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/xactly.png"),
      height: 330,
      width: 1000,
    },
  },
  // {
  //   id: "cleartax",
  //   href: "https://cleartax.in/",
  //   logo: {
  //     src: require("../images/sponsors/cleartax.png"),
  //     height: 977,
  //     width: 4485,
  //   },
  // },
  // {
  //   id: "goibibo",
  //   href: "https://www.goibibo.com/",
  //   logo: {
  //     src: require("../images/sponsors/goibibo.png"),
  //     height: 134,
  //     width: 375,
  //   },
  // },
  // {
  //   id: "atlantis",
  //   href: "https://www.atlantistech.co/",
  //   // scale: 0.625,
  //   logo: {
  //     src: require("../images/sponsors/atlantis.png"),
  //     height: 560,
  //     width: 2306,
  //   },
  // },
  {
    id: "quolum",
    href: "https://quolum.com/",
    scale: 1.2,
    logo: {
      src: require("../images/sponsors/quolum.png"),
      height: 128,
      width: 556,
    },
  },
  {
    id: "react-india",
    href: "https://www.reactindia.io/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/react_india_log.png"),
      height: 75,
      width: 75,
    },
  },
  {
    id: "recro",
    href: "https://recro.io/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/recro.png"),
      height: 60,
      width: 145,
    },
  },
  {
    id: "devfolio",
    href: "https://devfolio.co/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/devfolio.png"),
      height: 60,
      width: 289,
    },
  },
  {
    id: "peerlist",
    href: "https://peerlist.io/",
    scale: 1,
    logo: {
      src: require("../images/sponsors/peerlist.png"),
      height: 60,
      width: 197,
    },
  },
  // {
  //   id: "auth0",
  //   href: "https://auth0.com/",
  //   scale: 0.9,
  //   logo: {
  //     src: require("../images/sponsors/auth0.png"),
  //     height: 717,
  //     width: 2426,
  //   },
  // },
  // {
  //   id: "openhouse",
  //   href: "https://openhouse.study",
  //   scale: 1.1,
  //   logo: {
  //     src: require("../images/sponsors/openhouse.png"),
  //     height: 65,
  //     width: 378,
  //   },
  // },
  // {
  //   id: "hotstar",
  //   href: "https://www.hotstar.com",
  //   scale: 0.58,
  //   logo: {
  //     src: require("../images/sponsors/hotstar.png"),
  //     height: 100,
  //     width: 100,
  //   },
  // },
];

export default data;
