import React from "react";
import Image from "next/image";
import Link from "next/link";
import classNames from "classnames";
import logos from "../../data/logos";
import { useIsMobile } from "./hooks";
import { event } from "~/utils/gtag";

function getLogoDimensions(dimensions, scale) {
  return {
    width: Math.round(dimensions.width * scale),
    height: Math.round(dimensions.height * scale),
  };
}

export function Logo({ id, logo, href, className, scale }) {
  const isMobile = useIsMobile();
  const dimensions = getLogoDimensions(logo, scale || 1);
  function eventHandler() {
    event({
      action: "link_click",
      category: "logo",
      label: id,
    });
  }
  return (
    <Link href={href}>
      <a>
        <Image
          alt={id}
          src={logo.src}
          width={dimensions.width}
          height={dimensions.height}
          className={classNames("cursor-pointer", className)}
          onClick={eventHandler}
        />
      </a>
    </Link>
  );
}

export default function LogoById({ id, className }) {
  const logo = logos.find((p) => p.id === id);
  if (!logo) {
    return null;
  }

  return <Logo {...logo} id={id} className={className} />;
}
